import { ReactNode } from 'react';
import {
  Groups, Person, Star, Work,
} from '@mui/icons-material';
import { Answers, WhoAmI } from 'lib/types/users';

type Question = {
  [key in WhoAmI]: {
    icon: ReactNode;
    title: string;
    sub: string;
  };
};

interface Props {
  onNext: (a: Answers) => void;
}

export const Step1 = ({
  onNext,
}: Props) => {
  const whoAmI: Question = {
    solo: {
      icon: <Person fontSize="large" />,
      title: 'I mainly create content for others',
      sub: 'I\'m a freelancer or solo content creator.',
    },
    team: {
      icon: <Groups fontSize="large" />,
      title: 'I\'m part of a team',
      sub: 'I\'m a content creator working on a large content team.',
    },
    employer: {
      icon: <Work fontSize="large" />,
      title: 'I\'m an employer',
      sub: 'I want to hire people and manage my creative team.',
    },
    influencer: {
      icon: <Star fontSize="large" />,
      title: 'I\'m an influencer',
      sub: 'I primarily create for my own accounts.',
    },
  };

  const onClick = (who: WhoAmI) => {
    onNext({ who });
  };

  return (
    <>
      <h2 className="font-semibold text-xl mb-2">What best describes you?</h2>
      {
        Object.keys(whoAmI).map((w) => {
          const data = whoAmI[w as keyof Question];
          return (
            <button
              type="button"
              key={w}
              className="bg-white border hover:border-alpineTeal rounded-md p-4 mb-2 w-full"
              onClick={() => onClick(w as WhoAmI)}
            >
              <div className="flex">
                <div className="p-2 pr-4">
                  {data.icon}
                </div>
                <div>
                  <div className="md:text-lg font-semibold text-left">{data.title}</div>
                  <div className="text-sm text-left">{data.sub}</div>
                </div>
              </div>
            </button>
          );
        })
      }
    </>
  );
};
