import Link from 'next/link';

export interface ExploreModuleLink {
  name: string;
  url: string;
}
interface Props {
  links: ExploreModuleLink[];
}

export const ExploreModule = ({ links }: Props) => (
  <div className="max-w-screen-xl mx-auto p-6 md:py-10 text-center">
    <h3 className="text-white font-bold text-2xl mb-6">Explore Gondola</h3>
    <div className="flex flex-wrap justify-center gap-2 mb-2">
      {
        links.map((item) => (
          (
            <Link
              key={item.name}
              href={item.url}
            >

              <div className="bg-white md:text-lg rounded-3xl m-1 px-6 py-2">{item.name}</div>

            </Link>
          )
        ))
      }
    </div>
  </div>
);
