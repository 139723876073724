import { capitalize } from 'lodash';
import Link from 'next/link';
import { Emoji } from 'elements/Emoji';
import { Milestone, MilestoneCounts } from 'lib/types';
import { milestoneAssetUrl } from 'lib/utils/imageUtils';
import { MILESTONE_TYPES } from 'lib/constants';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { betaTag } from 'features/Milestones/Milestones';
import { MilestoneCardButton } from 'features/Milestones/MilestoneCardButton';

interface Props {
  milestones: Milestone[];
  milestoneCounts?: MilestoneCounts;
  showBeta?: boolean;
}

export const MilestonesVert = ({
  milestones,
  milestoneCounts,
  showBeta,
}: Props) => {
  const currentUser = useCurrentUser();
  const link = `/${currentUser?.username}/milestones`;
  const aText = milestoneCounts?.achievedCount ? `${milestoneCounts.achievedCount} of ${milestoneCounts.totalCount}` : '';

  return (
    <div className="p-4 shadow-sm border bg-white rounded-lg relative">
      {showBeta && <div className="absolute top-2 right-2">{betaTag}</div>}
      <div className="text-center w-full text-2xl font-bold">
        <Emoji emoji="🏆" description="trophy emoji" />
        {' '}
        Milestones
        {' '}
        <Emoji emoji="🏆" description="trophy emoji" />
      </div>
      <div className="grid grid-cols-3">
        {MILESTONE_TYPES.map((t) => {
          const milestone = milestones.find((ms) => ms.type === t);
          if (!milestone) return null;
          const cls = !milestone.achieved ? 'opacity-30' : '';
          const badgeType = (milestone.type === 'days' || milestone.type === 'weeks') ? 'w-3/4' : 'w-11/12';
          return (
            <div className="text-center pb-6 pt-4" key={milestone.id}>
              <div className="text-xl text-center font-semibold mb-4">
                {capitalize(milestone.type)}
              </div>
              <div className="w-3/4 m-auto">
                <MilestoneCardButton
                  milestone={milestone}
                  milestoneUserId={currentUser?.id || -1}
                >
                  <img src={milestoneAssetUrl(milestone)} className={`${cls} ${badgeType} m-auto`} alt={milestone.name} />
                </MilestoneCardButton>
              </div>
            </div>
          );
        })}
        <div className="text-center pb-6 pt-4">
          <div className="text-xl text-center font-semibold mb-4">
            Achieved
          </div>
          <div className="w-3/4 m-auto pb-6 text-xl">
            {aText}
          </div>
          <Link href={link} className="underline">
            View All
          </Link>
        </div>
      </div>
    </div>
  );
};
