import { useModal } from 'mui-modal-provider';
import { MilestoneCardModal } from 'components/MilestoneCardModal';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { Milestone } from 'lib/types';
import { ReactNode } from 'react';

interface Props {
  milestone: Milestone;
  children: ReactNode;
  milestoneUserId: number;
}

export const MilestoneCardButton = ({ children, milestone, milestoneUserId }: Props) => {
  const { showModal } = useModal();
  const currentUser = useCurrentUser();

  const onClick = (img: string) => {
    const modal = showModal(MilestoneCardModal, {
      img,
      onClose: () => {
        modal.destroy();
      },
    });
  };

  const disable = !milestone.cardUrl || currentUser?.id !== milestoneUserId;

  if (disable) {
    return <>{children}</>;
  }

  return (
    <button
      type="button"
      onClick={() => onClick(milestone.cardUrl || '')}
      className="hover:bg-hintgray"
      aria-label="click to share milestone"
    >
      {children}
    </button>
  );
};
