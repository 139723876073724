import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { User } from 'lib/types/users';
import {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
// import { ESServiceMeta } from 'server/api/types';
import { CreatorList } from '../LoggedInModules/CreatorList';

const CREATOR_LIST_SIZE = 6;

interface WeeklyCreatorResponse {
  creators: User[];
  endDate: string;
}

export const CreatorHighlights = () => {
  const API = useApi();
  const currentUser = useCurrentUser();

  const [creators, setCreators] = useState<WeeklyCreatorResponse| undefined>();
  const [photographers, setPhotographers] = useState<WeeklyCreatorResponse | undefined>();
  const [videographers, setVideographers] = useState<WeeklyCreatorResponse | undefined>();
  const [artists, setArtists] = useState<WeeklyCreatorResponse | undefined>();

  useEffect(() => {
    // As soon as a request for creators gets its payload, immediately set the state.
    // This allows some creator rows to be rendered as fast as they can be fetched.
    const fetchAndSetCreators = async (
      userPromise: Promise<User[]>,
      setFunc: Dispatch<SetStateAction<User[] | undefined>>,
    ) => {
      const users = await API.ignoreErrors(userPromise, []);
      setFunc(users);
    };

    const getCreators = async () => {
      fetchAndSetCreators(API.topWeeklyCreators([], 'currentEngagements', CREATOR_LIST_SIZE), setCreators as any);
      fetchAndSetCreators(API.topWeeklyCreators([24], 'currentEngagements', CREATOR_LIST_SIZE), setPhotographers as any);
      fetchAndSetCreators(API.topWeeklyCreators([25], 'currentViews', CREATOR_LIST_SIZE), setVideographers as any);
      fetchAndSetCreators(API.topWeeklyCreators([45, 54, 30, 31, 64, 29], 'currentEngagements', CREATOR_LIST_SIZE), setArtists as any);
    };

    getCreators();
  }, [currentUser, API]);

  return (
    <div className="bg-alpineTeal text-white">
      <div className="max-w-screen-xl m-auto p-6">
        <h2 className="text-2xl font-bold mb-4">Creator Highlights</h2>
        {!!photographers?.creators?.length && (
          <CreatorList
            title={`Photographers with the most engagements the week ending ${photographers.endDate}`}
            creators={photographers.creators}
            moreUrl="/creators?role=Photographer~24"
            moreTitle="View all photographers"
            id="photographers"
          />
        )}
        {!!videographers?.creators?.length && (
          <CreatorList
            title={`Videographers with the most views the week ending ${videographers.endDate}`}
            creators={videographers.creators}
            moreUrl="/creators?role=Videographer~25"
            moreTitle="View all videographers"
            id="videographers"
          />
        )}
        {!!artists?.creators?.length && (
          <CreatorList
            title={`Artists, designers, and animators with the most engagements the week ending ${artists.endDate}`}
            creators={artists.creators}
            moreUrl="/creators?role=Graphic+Artist~45&role=Graphic+Designer~54&role=Illustrator~30&role=Animator~31&role=Motion+Designer~64&role=VFX+Artist~29"
            moreTitle="View all artists, designers, and animators"
            id="artists"
          />
        )}
        {!!creators?.creators?.length && (
          <CreatorList
            title={`Creators with the most engagements the week ending ${creators.endDate}`}
            creators={creators.creators}
            moreUrl="/creators"
            moreTitle="View all creators"
            id="creators"
          />
        )}
      </div>
    </div>

  );
};
