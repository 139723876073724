import { User } from 'lib/types/users';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { Avatar } from 'components/Avatar';
import { ProfileLink } from 'components/ProfileLink';

interface Props {
  creator: User;
  rank?: number;
}

export const CreatorListCard = ({ creator, rank }: Props) => (
  <div className="relative bg-white rounded-lg shadow pt-6 px-3 pb-1">
    {rank && <div className="absolute font-semibold text-xl top-2 right-3 text-mediumgray">{`#${rank}`}</div>}
    <ProfileLink user={creator} context="creator-list-card">

      <Avatar
        src={imageAvatarUrl(creator.avatarUrl, 300)}
        userId={creator.id}
        alt={creator.name}
        isPro={creator.isPro}
      />
      <div className="py-3 text-center font-semibold text-mediumgray">
        {creator.name}
      </div>

    </ProfileLink>
  </div>
);
