import { ReactNode } from 'react';

export interface ActionStepCardProps {
  heading: string;
  body: ReactNode;
  cta?: ReactNode;
  stepNum: number;
  totalSteps?: number;
}

export const ActionStepCard = ({
  heading, body, cta, stepNum, totalSteps,
}: ActionStepCardProps) => (
  <div className={`flex flex-col w-full bg-white rounded-md text-center ${totalSteps === 2 ? 'md:w-1/2' : 'md:w-1/3'}`}>
    <div className="relative p-4 h-full">
      <div className="absolute z-40 inline-flex items-center justify-center w-10 h-10 text-xl font-bold text-darkgray bg-white border-4 border-orange rounded-full -top-4 left-0 right-0 m-auto">
        {stepNum}
      </div>

      <div className="flex flex-col h-full">
        <h3 className="text-xl font-semibold mb-2 mt-4">
          {heading}
        </h3>
        <div className="flex-grow">
          {body}
        </div>
        {cta}
      </div>
    </div>
  </div>
);
