import { ReactNode } from 'react';
import { CreatorsListUser } from 'lib/types/users';
import { CreatorInfo } from 'components/CreatorInfo';

interface Props {
  user: CreatorsListUser;
  text: ReactNode;
  showLinks?: boolean;
}

export const TestimonialCard = ({
  user, text, showLinks,
}: Props) => {
  if (!user) return null;

  const speakBubble = () => (
    <div className="w-5 absolute -top-8 left-4 overflow-hidden">
      <div className="h-8 bg-gray-200 rotate-45 transform origin-bottom-left" />
    </div>
  );

  return (
    <div className="relative max-w-3xl mx-auto">
      <div className="bg-white p-4 md:p-6 w-full rounded-2xl">
        <CreatorInfo user={user} showLinks={showLinks} hideSave />
      </div>
      <div className="relative mx-4 bottom-4">
        {speakBubble()}
        <div className="mx-auto py-4 px-6 bg-gray-200 rounded-2xl leading-6 font-medium text-lg">
          &quot;
          {text}
          &quot;
        </div>
      </div>
    </div>
  );
};
