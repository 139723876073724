import { capitalize } from 'lodash';
import Link from 'next/link';
import { Milestone, MilestoneCounts } from 'lib/types';
import { milestoneAssetUrl } from 'lib/utils/imageUtils';
import { MILESTONE_TYPES } from 'lib/constants';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { betaTag } from 'features/Milestones/Milestones';
import { MilestoneCardButton } from 'features/Milestones/MilestoneCardButton';

interface Props {
  milestones: Milestone[];
  milestoneCounts?: MilestoneCounts;
  showBeta?: boolean;
}

export const MilestonesHorz = ({
  milestones,
  milestoneCounts,
  showBeta,
}: Props) => {
  const currentUser = useCurrentUser();
  const link = `/${currentUser?.username}/milestones`;
  const aText = milestoneCounts?.achievedCount ? `${milestoneCounts.achievedCount} of ${milestoneCounts.totalCount} achieved` : '';

  return (
    <div className="flex flex-wrap justify-start border p-4 rounded-lg bg-white shadow-sm">
      <div className="flex pr-6 pb-2 lg:w-1/5">
        <div>
          <div className="text-xl font-bold flex gap-2 mb-2">
            Milestones
            {showBeta && <div>{betaTag}</div>}
          </div>
          <div className="flex lg:flex-col gap-1">
            <div>{aText}</div>
            <div>
              <Link href={link} className="underline">
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly w-full lg:w-4/5">
        {MILESTONE_TYPES.map((t) => {
          const milestone = milestones.find((ms) => ms.type === t);
          if (!milestone) {
            return (
              <div className="w-1/5" key={t} />
            );
          }
          const cls = !milestone.achieved ? 'opacity-30' : '';
          const badgeType = (milestone.type === 'days' || milestone.type === 'weeks') ? 'w-3/5' : 'w-11/12';
          return (
            <div className="pr-2 w-1/5 lg:px-2 h-full flex flex-col justify-between" key={t}>
              <div className="font-semibold text-l text-center mb-2">
                {capitalize(milestone.type)}
              </div>
              <MilestoneCardButton
                milestone={milestone}
                milestoneUserId={currentUser?.id || -1}
              >
                <img src={milestoneAssetUrl(milestone)} className={`${cls} ${badgeType} m-auto`} alt={milestone.name} />
              </MilestoneCardButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};
