import { BlogPost } from 'lib/types';
import { BlogPostList } from './BlogPostList';

interface Props {
  blogPosts?: BlogPost[];
}
export const BlogModule = ({ blogPosts }: Props) => {
  if (!blogPosts?.length) {
    return null;
  }

  return (
    <div className="max-w-screen-xl mx-auto p-6 md:py-10">
      <h3 className="font-bold text-2xl mb-6 text-center">
        <a href="/blog" className="hover:underline">
          From the Gondola blog
        </a>
      </h3>

      <BlogPostList blogPosts={blogPosts} noPagination />
    </div>
  );
};
