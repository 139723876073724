import { trackClick } from 'lib/gtag';
import PersonSearchRounded from '@mui/icons-material/PersonSearchRounded';
import ImageSearchRounded from '@mui/icons-material/ImageSearchRounded';
import Link from 'next/link';
import { TextAnimation } from './TextAnimation';

export const HeroModule = () => {
  const ContentBox = () => (
    <div className="py-10">
      <div className="font-bold text-4xl md:text-7xl text-orange my-4">
        <TextAnimation />
        <div className="text-black my-2 text-2xl md:text-5xl lg:mb-8">
          THE CONTENT YOU MAKE
        </div>
      </div>
      <h1 className="my-2 md:my-6 text-xl md:text-3xl font-medium">
        Credits, analytics, and portfolios for the entire creative industry.
      </h1>
      <div className="mt-2">
        <Link
          className="btn-primary"
          href="/signup"
        >
          Join Gondola for free
        </Link>
      </div>
      <div className="flex justify-center mt-4 space-x-8">
        <Link
          href="/explore"
          className="btn bg-teal text-white hover:bg-alpineTeal"
          onClick={() => {
            trackClick('clickButton', { source: 'homepage', destination: '/explore' });
          }}
        >
          <div className="flex items-center">
            <ImageSearchRounded />
            Explore Posts
          </div>
        </Link>
        <Link
          href="/creators"
          className="btn-secondary"
          onClick={() => {
            trackClick('clickButton', { source: 'homepage', destination: '/creators' });
          }}
        >
          <div className="flex items-center">
            <PersonSearchRounded />
            Find Creators
          </div>
        </Link>
      </div>
    </div>
  );

  return (
    <div className="relative max-w-screen-xl mx-auto">
      <div className="flex justify-center items-center p-6 pb-0 md:py-10 lg:py-10 text-center">
        <ContentBox />
      </div>
    </div>
  );
};
