import { ReactNode } from 'react';
import { CardMedia } from '@mui/material';
import Link from 'next/link';
import { Post } from 'lib/types';
import { createAltTextForPosts } from 'lib/helpers';
import { imagePostUrl } from 'lib/utils/imageUtils';

interface Props {
  matchId: string;
  /** This is post count belong to the criteria/user */
  matchCount: number;
  /** This is post count for the whole set */
  matchTotalCount: number;
  post: Post;
  children: ReactNode;
  fixedSize?: { width: number; height: number };
}

export const MatchesCard = ({
  matchId,
  matchCount,
  matchTotalCount,
  post,
  children,
  fixedSize,

}: Props) => {
  const path = `/matches/${matchId}`;
  const imgBgUrl = imagePostUrl(post.thumbnailUrl, {
    width: fixedSize?.width || 400,
    height: fixedSize?.height,
    isDead: !post.isLive,
  });
  const matchImg = imgBgUrl && (
    <CardMedia
      component="img"
      alt={createAltTextForPosts(post)}
      image={imgBgUrl}
      loading="lazy"
    />
  );

  return (
    <div className="w-full mb-6">
      <div className="flex justify-center items-center">
        <div className="flex relative">
          {matchCount > 2 && (
            <div className="bg-white border border-lightgray absolute top-4 -right-4 rounded-md w-full h-full pt-10">
              {matchImg}
            </div>
          )}
          {matchCount > 1 && (
            <div className="bg-white border border-lightgray absolute top-2 -right-2 rounded-md w-full h-full pt-10">
              {matchImg}
            </div>
          )}
          <div className="bg-white relative rounded-2xl h-full">
            <div className="absolute top-12 left-2 z-10">
              <Link
                href={path}
                className="align-middle rounded-3xl bg-hintgray p-2 text-sm cursor-pointer hover:bg-orange hover:text-white"
              >

                {matchTotalCount}
                {' '}
                matching posts
              </Link>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
