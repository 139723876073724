import {
  useState, useRef, UIEvent,
} from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Post } from 'lib/types';
import { PostCard } from 'components/PostCard';
import { MatchesCard } from 'components/MatchesCard';
import { FeaturedTag } from 'components/FeaturedTag';
import { POST_CARD_IMG_WIDTH } from 'lib/utils/imageUtils';

const itemWidth = 215;
interface Props {
  posts: Post[];
}
const toolTipTitle = 'As a Gondola PRO, your posts are randomly featured.';

export const Carousel = ({ posts }: Props) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const carousel = useRef<HTMLDivElement>(null);

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const scroll = e.currentTarget.scrollLeft;
    setScrollLeft(scroll);
  };

  const goLeft = () => {
    if (!carousel?.current) return;

    if (scrollLeft <= 0) return;
    carousel.current.scrollLeft = scrollLeft - itemWidth;
  };

  const goRight = () => {
    if (!carousel?.current) return;

    carousel.current.scrollLeft = scrollLeft + itemWidth;
  };

  const disableLeft = () => {
    if (!carousel?.current) return true;

    if (carousel.current.scrollLeft <= 0) return true;

    return false;
  };

  const disableRight = () => {
    if (!carousel?.current) return false;

    const sw = carousel.current.scrollWidth;
    const ow = carousel.current.offsetWidth;
    const noScroll = sw === ow;
    const endScroll = sw === ow + carousel.current.scrollLeft;
    if (noScroll || endScroll) return true;

    return false;
  };

  return (
    <div className="relative overflow-hidden">
      <button
        type="button"
        className="absolute left-2 top-1/2 -translate-y-1/2 z-20 bg-white opacity-70 hover:opacity-100 disabled:opacity-30 rounded-full"
        onClick={goLeft}
        disabled={disableLeft()}
        aria-label="previous"
      >
        <KeyboardArrowLeft fontSize="large" />
      </button>
      <button
        type="button"
        className="absolute right-2 top-1/2 -translate-y-1/2 z-20 bg-white opacity-70 hover:opacity-100 disabled:opacity-30 rounded-full"
        onClick={goRight}
        disabled={disableRight()}
        aria-label="next"
      >
        <KeyboardArrowRight fontSize="large" />
      </button>
      <div
        ref={carousel}
        onScroll={onScroll}
        className="relative flex gap-4 overflow-x-scroll scroll-smooth snap-x z-0 items-center"
      >
        {
          posts.map((post) => (
            <div
              key={post.id}
              className="relative snap-start pt-3"
            >
              {post.isFeatured && (
                <div className="absolute z-10 right-0 top-0">
                  <FeaturedTag
                    title={toolTipTitle}
                    upgradeTitle="Gondola creators with PRO accounts get their posts randomly featured. Sign up for PRO to join them!"
                  />
                </div>
              )}
              {post.matchCount && post.matchTotalCount && post.matchId
                ? (
                  <MatchesCard
                    key={post.id}
                    post={post}
                    matchCount={post.matchTotalCount}
                    matchTotalCount={post.matchTotalCount}
                    matchId={post.matchId}
                    fixedSize={{ width: POST_CARD_IMG_WIDTH, height: POST_CARD_IMG_WIDTH }}
                  >
                    <PostCard
                      post={post}
                      includeCreditPreview
                      includeMetrics
                      mediaClassName="w-full min-w-[300px] md:min-w-[250px]"
                      context="featured-carousel"
                      fixedSize={{ width: POST_CARD_IMG_WIDTH, height: POST_CARD_IMG_WIDTH }}
                    />
                  </MatchesCard>
                ) : (
                  <PostCard
                    key={post.id}
                    post={post}
                    includeMetrics
                    includeCreditPreview
                    mediaClassName="w-full min-w-[300px] md:min-w-[250px]"
                    context="featured-carousel"
                    fixedSize={{ width: POST_CARD_IMG_WIDTH, height: POST_CARD_IMG_WIDTH }}
                  />
                )}
            </div>
          ))
        }
      </div>
    </div>
  );
};
