import { useEffect, useState } from 'react';
import {
  LinearProgress,
} from '@mui/material';
import { useApplicationState } from 'lib/contexts/ApplicationState';
import {
  WhoAmI, PrimaryReason, NextStep, Answers,
} from 'lib/types/users';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

export const NextSteps = () => {
  const { api, currentUser } = useApplicationState();
  const [nextSteps, setNextSteps] = useState<NextStep | undefined>();
  const [isHidden, setIsHidden] = useState(true);
  const [step, setStep] = useState(1);
  const [_who, setWho] = useState<WhoAmI | undefined>(nextSteps?.whoAmI);
  const [_reason, setReason] = useState<PrimaryReason | undefined>(nextSteps?.primaryReason);

  const prev = () => {
    if (step >= 2) {
      setStep(step - 1);
    }
  };

  const next = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const save = async ({ who, reason }: Answers) => {
    if (who) {
      setWho(who);
    }
    if (reason) {
      setReason(reason);
    }
    await api.saveUserData({
      whoAmI: who,
      primaryReason: reason,
    });
    next();
  };

  const onClose = async () => {
    setIsHidden(true);
    await api.saveUserData({
      nextStepsDone: true,
    });
  };

  useEffect(() => {
    // nextSteps comes from the API, if it's already set we can jump ahead
    if (nextSteps?.primaryReason) {
      setStep(3);
    } else if (nextSteps?.whoAmI) {
      setStep(2);
    }

    setWho(nextSteps?.whoAmI);
    setReason(nextSteps?.primaryReason);
    setIsHidden(!nextSteps || nextSteps?.nextStepsDone);
  }, [nextSteps]);

  useEffect(() => {
    const getUserSteps = async () => {
      const userSteps = await api.getUserNextSteps();
      setNextSteps(userSteps);
    };
    if (currentUser) {
      getUserSteps();
    }
  }, [api, currentUser]);

  if (!currentUser || isHidden) {
    return null;
  }

  const getStep = () => {
    switch (step) {
    case 1:
      return <Step1 onNext={save} />;
    case 2:
      return (
        <Step2
          who={_who}
          onBack={prev}
          onNext={save}
        />
      );
    case 3:
      return <Step3 who={_who} reason={_reason} onBack={prev} onClose={onClose} />;
    default:
      return <Step1 onNext={save} />;
    }
  };

  return (
    <div className="bg-hintgray">
      <div className="max-w-screen-xl m-auto p-6">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3">
            <h1 className="text-2xl font-bold mb-2">Welcome to Gondola</h1>
            <p className="font-semibold mb-2">
              Get started (
              {step}
              {' '}
              of 3)
            </p>
            <div className="md:pr-10 pb-4">
              <LinearProgress
                variant="determinate"
                value={step * 33.3}
                color="secondary"
                sx={{
                  height: 20,
                  borderRadius: 5,
                }}
              />
            </div>
          </div>
          <div className="w-full md:w-2/3">
            {getStep()}
          </div>
        </div>
      </div>
    </div>
  );
};
