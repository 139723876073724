import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import { PostServiceStats } from 'server/api/types';
import { Milestone, MilestoneCounts } from 'lib/types';
import { ProfileUser } from 'lib/types/users';
import { ProfileLink } from 'components/ProfileLink';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { Emoji } from 'elements/Emoji';
import { useApi } from 'lib/contexts/ApplicationState';
import { MobileTooltip } from 'components/MobileTooltip';
import { MilestoneList } from './MilestoneList';

interface Props {
  profileUser: ProfileUser;
  milestoneCounts: MilestoneCounts;
}

export const betaTag = (
  <MobileTooltip
    title="Milestones is a brand new feature! Please let us know what you think or if you see anything wrong."
  >
    <span className="cursor-default text-xs font-bold inline-block py-1 px-2 rounded-full  bg-teal uppercase">
      Beta
    </span>
  </MobileTooltip>
);

export const Milestones = ({
  profileUser,
  milestoneCounts,
}: Props) => {
  const API = useApi();
  const [stats, setStats] = useState<PostServiceStats>();
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [streaks, setStreaks] = useState<any>();

  useEffect(() => {
    const getMilestones = async () => {
      const ms = await API.getUserMilestones(profileUser.id);
      setMilestones(ms);
    };

    const getStats = async () => {
      const { meta } = await API.getProfilePosts(profileUser.id, { skipPagination: true });
      setStats(meta.stats);
    };

    const getStreaks = async () => {
      const s = await API.getUserStreaks(profileUser.id);
      setStreaks(s);
    };

    if (profileUser) {
      getMilestones();
      getStats();
      getStreaks();
    }
  }, [profileUser]);

  return (
    <div className="max-w-screen-lg m-auto h-full p-4">
      <h1 className="text-2xl font-bold my-2">
        Milestones
        {' '}
        {betaTag}
      </h1>
      <div className="flex flex-wrap gap-2 items-center justify-between">
        <div className="flex gap-2 items-center">
          <ProfileLink
            user={profileUser}
            context="milestones"
          >
            <Avatar
              variant="circular"
              alt={profileUser.name}
              src={imageAvatarUrl(profileUser.avatarUrl)}
              imgProps={{ loading: 'lazy' }}
              className="hover:border-2 border-orange"
            />
          </ProfileLink>
          <h2 className="font-bold text-xl">
            <ProfileLink
              user={profileUser}
              context="milestones"
            >
              {profileUser.name}
            </ProfileLink>
          </h2>
        </div>
        {milestoneCounts.achievedCount > 0 && (
          <h3 className="text-xl">
            <span className="font-semibold text-2xl">{milestoneCounts.achievedCount}</span>
            {' '}
            milestones achieved!
            {' '}
            <Emoji emoji="🎉" description="tada" />
          </h3>
        )}
      </div>
      <MilestoneList type="posts" milestones={milestones.filter((m) => m.type === 'posts')} total={stats?.totalPosts} profileUser={profileUser} />
      <MilestoneList type="views" milestones={milestones.filter((m) => m.type === 'views')} total={stats?.totalCombinedViews} profileUser={profileUser} />
      <MilestoneList type="likes" milestones={milestones.filter((m) => m.type === 'likes')} total={stats?.totalLikes} profileUser={profileUser} />
      <MilestoneList type="days" milestones={milestones.filter((m) => m.type === 'days')} total={streaks?.dailyStreak} profileUser={profileUser} />
      <MilestoneList type="weeks" milestones={milestones.filter((m) => m.type === 'weeks')} total={streaks?.weeklyStreak} profileUser={profileUser} />
      <MilestoneList type="years" milestones={milestones.filter((m) => m.type === 'years')} total={streaks?.anniversary} profileUser={profileUser} />
    </div>
  );
};
