import { capitalize } from 'lodash';
import Info from '@mui/icons-material/Info';
import { STAT_TEXT, ORDERED_STATS_TOTAL } from 'lib/constants';
import { abbreviateNum } from 'lib/helpers';
import { Stats } from 'lib/types';
import { MobileTooltip } from 'components/MobileTooltip';

interface Props {
  title: string;
  subtitle: string;
  stats: Stats;
  deltas: Stats;
  infoText?: string;
}

export const StatWithDeltaCard = ({
  title,
  subtitle,
  stats,
  deltas,
  infoText,
}: Props) => {
  const showPercent = (n: number) => {
    const percent = Math.round(n * 1000.0) / 10.0;
    if (percent === 0.0) {
      return null;
    }
    return (
      <div className={`pl-2 text-base font-bold ${n >= 0 ? 'text-green' : 'text-red'}`}>
        {`${n > 0 ? '+' : ''}${percent}`}
        %
      </div>
    );
  };

  const renderInfo = () => {
    if (!infoText) {
      return null;
    }
    return (
      <MobileTooltip title={infoText} className="ml-1">
        <Info fontSize="inherit" color="secondary" />
      </MobileTooltip>
    );
  };

  return (
    <div className="flex flex-wrap justify-start border p-4 rounded-lg bg-white shadow-sm my-4">
      <div className="flex pr-6 pb-2 w-full lg:w-1/5">
        <div>
          <div className="text-xl font-bold uppercase">
            {title}
            {renderInfo()}
          </div>
          <div className="text-l" suppressHydrationWarning>{subtitle}</div>
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly gap-4 w-full lg:w-4/5">
        {ORDERED_STATS_TOTAL.map((key) => {
          const text = STAT_TEXT[key];
          const stat = Math.max(stats[key as keyof typeof stats], 0);
          const delta = Math.max(deltas[key as keyof typeof deltas], 0);
          return (
            <div className="pr-4 lg:px-2" key={key}>
              <div className="font-semibold text-l">
                {capitalize(text)}
              </div>
              <div className="text-2xl">
                {abbreviateNum(stat, 1, true)}
                {showPercent(delta)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
