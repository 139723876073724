import { Close, OpenInNew } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { WEB_ASSETS_URL } from 'lib/utils/imageUtils';

interface Props {
  onClose: () => void;
}

export const HowToCredit = ({
  onClose,
}: Props) => (
  <div className="border p-4 mx-auto rounded-md bg-hintgray relative">
    <div className="absolute right-2 top-2">
      <IconButton aria-label="close" onClick={onClose}>
        <Close color="primary" />
      </IconButton>
    </div>
    <h6 className="font-bold text-xl mb-2">
      CREDITING 101
    </h6>
    <div>
      Get credit for your great work!
      <ol className="list-decimal p-4">
        <li>Select the owner of this media</li>
        <li>Select their role</li>
        <li>Click Save or Save & Add Collaborator</li>
        <li>Select a collaborator</li>
        <li>Select their roles (Tip: you can select one or more roles)</li>
        <li>
          If a company
          or another creator hired you for the piece, add them as Nest Under.
        </li>
      </ol>
      <div className="mb-4">
        Make sure to list all those who had a part in bringing the piece of content to life!
      </div>
    </div>
    <div className="h-full m-auto">
      <video
        loop
        id="video-12"
        className="rounded-xl aspect-video"
        autoPlay
        muted
        playsInline
        width="100%"
      >
        <source src={`${WEB_ASSETS_URL}home/benefit-12.mp4`} type="video/mp4" />
      </video>
      <div className="mt-4">
        <a
          href="https://www.loom.com/share/26f45bc3578b4bb0b1e62616b28d95fc"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          See a video tutorial
          {' '}
          <OpenInNew fontSize="small" />
        </a>
      </div>
    </div>
  </div>
);
