import { capitalize } from 'lodash';
import { STAT_TEXT, ORDERED_STATS_TOTAL } from 'lib/constants';
import { abbreviateNum } from 'lib/helpers';
import { Stats } from 'lib/types';

interface Props {
  title: string;
  subtitle: string;
  stats: Stats;
}

export const StatCard = ({
  title,
  subtitle,
  stats,
}: Props) => (
  <div className="flex flex-wrap justify-start border p-4 rounded-lg bg-white shadow-sm">
    <div className="flex pr-6 pb-2 w-full lg:w-1/5">
      <div>
        <div className="text-xl font-bold uppercase">{title}</div>
        <div className="text-l" suppressHydrationWarning>{subtitle}</div>
      </div>
    </div>
    <div className="flex flex-wrap justify-evenly gap-4 w-full lg:w-4/5">
      {ORDERED_STATS_TOTAL.map((key) => {
        const text = STAT_TEXT[key];
        const stat = stats[key as keyof typeof stats];
        return (
          <div className="pr-2 lg:px-2" key={key}>
            <div className="font-semibold text-l">
              {capitalize(text)}
            </div>
            <div className="text-2xl">
              {abbreviateNum(stat, 1)}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);
