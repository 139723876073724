import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  img?: string;
  onClose: () => void;
}

export const MilestoneCardModal = ({
  img, onClose,
}: Props) => {
  if (!img) return null;

  return (
    <Dialog open>
      <div className="p-4">
        <div className="absolute right-2 top-2">
          <IconButton onClick={onClose} aria-label="close modal">
            <CloseIcon />
          </IconButton>
        </div>
        <h1 className="font-semibold text-xl">Share your accomplishment!</h1>
        <h2 className="mb-4">Tag us @ongondola and #RollTheCredits</h2>
        <img src={img} alt="milestone card" />
      </div>
    </Dialog>
  );
};
