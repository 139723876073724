import { Dialog } from '@mui/material';
import { HowToCredit } from './HowToCredit';

interface Props {
  onClose: () => void;
}

export const HowToCreditModal = ({
  onClose,
}: Props) => (
  <Dialog open>
    <HowToCredit onClose={onClose} />
  </Dialog>
);
