import { BlogPost } from 'lib/types';
import { Job } from 'lib/types/jobs';
import { BlogModule } from 'components/Blog/BlogModule';
import { ExploreModule, ExploreModuleLink } from 'components/ExploreModule';
import { HeroModule } from './LoggedOutModules/HeroModule';
import { TestimonialsModule } from './LoggedOutModules/TestimonialsModule';
import { BenefitModules } from './LoggedOutModules/BenefitModules';
import { CreatorHighlights } from './LoggedOutModules/CreatorHighlights';
import { JobsModule } from './LoggedOutModules/JobsModule';

interface Props {
  exploreLinks: ExploreModuleLink[];
  blogPosts?: BlogPost[];
  jobs?: Job[];
}

export const LoggedOutHome = ({ blogPosts, exploreLinks, jobs }: Props) => (
  <div>
    <HeroModule />
    <BenefitModules />
    <JobsModule serverSideJobs={jobs} />
    <CreatorHighlights />
    <div className="bg-orange">
      <ExploreModule links={exploreLinks} />
    </div>
    <div className="bg-teal px-6 pt-16">
      <TestimonialsModule />
    </div>
    <BlogModule blogPosts={blogPosts} />
  </div>
);
