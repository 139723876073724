/* eslint-disable jsx-a11y/media-has-caption */
import { ReactNode } from 'react';

interface Props {
  id: number;
  heading: ReactNode;
  description: ReactNode;
  imgUrl?: string;
  circleImageUrl?: string;
  circleImageAlt: string;
  isLazy: boolean;
}

export const BenefitsModule = ({
  id,
  heading,
  description,
  imgUrl,
  circleImageUrl,
  circleImageAlt,
  isLazy,
}: Props) => (
  <div className="relative max-w-screen-xl mx-auto px-6 xl:px-0 py-10 lg:py-24 flex flex-col lg:flex-row justify-between">
    <div className="text-center lg:text-left lg:w-5/12 lg:pr-6 mx-2 md:mx-0 my-auto">
      <img
        className="rounded-full w-32 p-1 bg-white mx-auto lg:mx-0 aspect-square"
        alt={circleImageAlt}
        src={circleImageUrl}
        width="120"
        height="120"
        loading={isLazy ? 'lazy' : undefined}
      />
      <h3 className="text-3xl my-6">
        {heading}
      </h3>
      <div className="text-lg xl:text-xl my-6 md:mb-8">
        {description}
      </div>
    </div>
    <div className="lg:w-7/12 m-auto">
      <video
        loop
        id={`video-${id}`}
        className="rounded-xl aspect-video"
        autoPlay
        muted
        playsInline
        width="100%"
      >
        <source
          src={imgUrl}
          type="video/mp4"
        />
      </video>
    </div>
  </div>
);
