import { BlogPost } from 'lib/types';
import { Pagination } from 'components/Pagination/BasicPagination';
import { useEffect, useState } from 'react';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useRouter } from 'next/router';
import { ServiceMeta } from 'server/api/types';
import { useSnackbar } from 'notistack';
import { BlogCard } from './BlogCard';

interface Props {
  blogPosts?: BlogPost[];
  blogMeta?: any;
  noPagination?: boolean;
}

export const BlogPostList = ({ blogPosts, blogMeta, noPagination = false }: Props) => {
  const [posts, setPosts] = useState<BlogPost[]>(blogPosts || []);
  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState<ServiceMeta>(blogMeta || undefined);
  const API = useApi();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  const fetchPosts = async () => {
    setIsLoading(true);
    try {
      const results = await API.listBlogPosts({ ...router.query, size: 8 });
      setPosts(results.data);
      setMeta(results.meta);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(`Error loading matches: ${error}`, {
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [router.query]);

  useEffect(() => {
    if (!blogPosts) {
      fetchPosts();
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-2">
        {isLoading ? 'Loading...'
          : posts.map((post) => (
            <div className="col-span-1" key={post.sourceId}>
              <BlogCard
                post={post}
              />
            </div>
          ))}
      </div>
      {(meta && !noPagination) && <Pagination paging={meta.paging} canSeeAllPages />}
    </>
  );
};
