import { A } from 'components/A';

export const NoPaginationBox = () => (
  <div className="p-6 border rounded-lg text-center">
    <div className="font-semibold text-xl">
      SIGN UP FOR FREE TO KEEP EXPLORING!&nbsp;
      <span role="img" aria-label="lock">🔐</span>
    </div>
    To access more than the first page of results,
    {' '}
    <A
      href="/signup"
      className="text-orange font-bold underline"
    >
      sign in or sign up for an account
    </A>
    !
  </div>
);
