import { BlogPost } from 'lib/types';

interface Props {
  post: BlogPost;
}

export const BlogCard = ({
  post,
}: Props) => (
  <div key={post.sourceId} className="rounded-lg overflow-hidden border h-full">
    <a href={`/blog/${post.slug}`}>
      <img src={post.thumbnailUrl} alt={post.title} loading="lazy" />
    </a>
    <div className="px-6 py-4">
      <div className="text-sm text-mediumgray mb-2">
        {new Date(post.publishedAt).toLocaleString(
          'en-us', { month: 'short', day: 'numeric', year: 'numeric' },
        )}
      </div>

      <div className="mb-2">
        <a href={`/blog/${post.slug}`} className="font-semibold text-lg">
          {post.title}
        </a>
      </div>
      <p>
        {post.teaser}
      </p>
    </div>
    <div className="px-6 pb-6 flex flex-wrap gap-2">
      {post.categories.map((category) => (
        <span
          className="bg-gray-200 rounded-full px-3 py-1 text-sm"
          key={category}
        >
          {category}
        </span>
      ))}
    </div>
  </div>
);
