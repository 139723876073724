const convertZuluDate = (zuluDate?: string | Date) => (zuluDate
  ? (new Date(zuluDate)).toLocaleDateString()
  : null);

const convertLocalTime = (zuluDate?: string | Date) => (zuluDate
  ? (new Date(zuluDate)).toLocaleString()
  : null);

const convertLocalDate = (zuluDate?: string | Date) => (zuluDate
  ? (new Date(zuluDate)).toLocaleDateString()
  : null);

export { convertZuluDate, convertLocalTime, convertLocalDate };
