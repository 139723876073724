import { HowToCreditModal } from 'components/HowToCredit/HowToCreditModal';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { useModal } from 'mui-modal-provider';
import Link from 'next/link';
import { WhoAmI, PrimaryReason } from 'lib/types/users';
import { ActionStepCard } from './ActionStepCard';

interface Props {
  who?: WhoAmI;
  reason?: PrimaryReason;
  onBack: () => void;
  onClose: () => void;
}

export const Step3 = ({
  who,
  reason,
  onBack,
  onClose,
}: Props) => {
  const { showModal } = useModal();
  const currentUser = useCurrentUser();
  const profileUrl = `/${currentUser?.username}`;
  return (
    <>
      <h2 className="font-semibold text-xl mb-6">Steps to get started on Gondola</h2>
      {reason !== 'hiring' && (who !== 'influencer' ? (
        <div className="flex flex-wrap md:flex-nowrap gap-6 mb-4">
          <ActionStepCard
            stepNum={1}
            heading="Track a post"
            body={(
              <>
                Copy and paste a social media post url from our supported platforms
                into the
                {' '}
                <span className="font-semibold">Track a link</span>
                {' '}
                box above.
              </>
            )}
          />
          <ActionStepCard
            stepNum={2}
            heading="Add a credit"
            body={(
              <>
                Open the post, click the
                {' '}
                <span className="font-semibold">Give Credit</span>
                {' '}
                button and follow the instructions
                to give credit where credit is due!
              </>
            )}
            cta={(
              <button
                type="button"
                className="btn-primary mt-4"
                onClick={() => {
                  const modal = showModal(HowToCreditModal, {
                    onClose: () => {
                      modal.destroy();
                    },
                  });
                }}
              >
                Learn more
              </button>
            )}
          />
          <ActionStepCard
            stepNum={3}
            heading="Show off your work"
            body={(
              <>
                Show your posts, credits, and analytics in one place,
                making it easy for you to curate your work across social media platforms
                and accounts!
              </>
            )}
            cta={(
              <Link href={profileUrl} className="btn-primary mt-4">

                View my profile

              </Link>
            )}
          />
        </div>
      ) : (
        <div className="flex flex-wrap md:flex-nowrap gap-6 mb-4">
          <ActionStepCard
            totalSteps={2}
            stepNum={1}
            heading="Connect your accounts"
            body={(
              <>
                Connect your social media accounts, and we will do the rest!
              </>
            )}
            cta={(
              <Link href="/settings/accounts" className="btn-primary mt-4">

                Connect Now

              </Link>
            )}
          />

          <ActionStepCard
            totalSteps={2}
            stepNum={2}
            heading="See your work"
            body={(
              <>
                Go to your profile to see your posts.
              </>
            )}
            cta={(
              <Link href={profileUrl} className="btn-primary mt-4">

                View my profile

              </Link>
            )}
          />

        </div>
      ))}
      {reason === 'hiring' && (
        <div className="flex flex-wrap md:flex-nowrap gap-6 mb-4">

          <ActionStepCard
            totalSteps={2}
            stepNum={1}
            heading="Search for creators"
            body={(
              <>
                Our extensive search capabilities can help you find people you are looking for.
              </>
            )}
            cta={(
              <Link href="/creators" className="btn-primary mt-4">

                Search Now

              </Link>
            )}
          />

          <ActionStepCard
            totalSteps={2}
            stepNum={2}
            heading="Manage lists of prospects"
            body={(
              <>
                Use
                {' '}
                <span className="font-semibold">Gondola for Teams</span>
                {' '}
                to find and hire creators.
                We would be happy to walk you through the features and offering.
                Request a demo today!
              </>
            )}
            cta={(
              <Link href="/plans/request-a-demo" className="btn-primary mt-4">

                Request a Demo

              </Link>
            )}
          />
        </div>

      )}
      <div className="flex justify-between">
        <button
          type="button"
          className="btn-primary-outlined bg-white"
          onClick={onBack}
        >
          Go Back
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={onClose}
        >
          Got it!
        </button>
      </div>
    </>
  );
};
