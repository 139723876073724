import { getAllowedMetrics } from 'components/PostMetrics/helpers';
import { MetricProps } from 'components/PostMetrics/types';
import { abbreviateNum } from 'lib/helpers';
import { Post } from 'lib/types';

interface Props {
  post: Post;
  decimals: number;
}

export const PostDeltas = ({
  post,
  decimals,
}: Props) => {
  const allowedMetrics = getAllowedMetrics(post.network, false);
  return (
    <div className="flex flex-wrap justify-start border p-4 rounded-lg bg-white shadow-md my-4">
      <div className="w-full">
        <div
          className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 gap-4 justify-items-center"
        >
          { allowedMetrics.map((metric: MetricProps) => {
            const metricValue = post[metric.field] as number;
            const deltaMetricValue = post[metric.deltaField] as number;
            if (!metricValue && !metric.renderIfNull) {
              return null;
            }
            return (
              <div className="flex flex-wrap w-full justify-items-center" key={`post-${post.id}-${metric.field}`}>
                <div className="text-base font-bold pr-2">
                  {metric.icon}
                  {' '}
                  {abbreviateNum(metricValue, decimals)}
                </div>
                {deltaMetricValue > 0 && (
                  <div className="font-base text-green">
                    +
                    {abbreviateNum(deltaMetricValue, decimals)}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
