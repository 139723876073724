import { ReactNode, useEffect, useState } from 'react';
import {
  Visibility, VisibilityOff,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput,
} from '@mui/material';
import {
  getAuth,
  createUserWithEmailAndPassword,
  linkWithCredential,
  EmailAuthProvider,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import Link from 'next/link';
import { firebaseApp } from 'lib/firebase';

interface Props {
  onSave: () => void;
  onCancel: () => void;
}

const NewerCredentialRequired = (
  <>
    You need a newer login credential in order to link an email address sign in.
    Please
    {' '}
    <a href="/signout?returnTo=/signin" className="underline">
      logout
    </a>
    {' '}
    and sign in to Gondola via Google.
  </>
);

const UnexpectedError = (
  <>
    We encountered an unexpected error connecting your email login to your account.
    Please
    {' '}
    <Link href="/contact" className="underline">

      contact support

    </Link>
    {' '}
    for assistance.
  </>
);

export const AddEmailModal = ({ onSave, onCancel }: Props) => {
  const API = useApi();
  const currentUser = useCurrentUser();
  const auth = getAuth(firebaseApp);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  const [email, setEmail] = useState(currentUser?.email || '');
  const [emailErr, setEmailErr] = useState('');

  const [pwd, setPwd] = useState('');
  const [pwdErr, setPwdErr] = useState('');
  const [showPwd, setShowPwd] = useState(false);

  const [rtPwd, setRtPwd] = useState('');
  const [rtPwdErr, setRtPwdErr] = useState('');
  const [showRtPwd, setShowRtPwd] = useState(false);

  const [submitErr, setSubmitErr] = useState<ReactNode>('');

  const [isSaving, setIsSaving] = useState(false);

  const checkEmail = () => {
    if (!email?.trim()) {
      setEmailErr('Email is required.');
    } else {
      setEmailErr('');
    }
  };

  const checkPwd = (onErr: (msg: string) => void, password?: string) => {
    if (!password || password.length < 6) {
      onErr('Password cannot be shorter than 6 charaters.');
      return;
    }

    if (pwd && rtPwd && pwd !== rtPwd) {
      onErr('Passwords do not match.');
      return;
    }

    onErr('');
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setFirebaseUser);
    return unsubscribe;
  }, []);

  const signUp = async () => {
    checkEmail();
    checkPwd(setPwdErr, pwd);
    checkPwd(setRtPwdErr, rtPwd);
    if (!email || !pwd || emailErr || pwdErr || rtPwdErr) return;

    setIsSaving(true);

    try {
      const credential = await createUserWithEmailAndPassword(auth, email, pwd);
      if (credential) {
        const { user } = credential;
        const token = await user.getIdToken();

        await API.signUpWithEmail(
          {
            email,
            isEmailVerified: user.emailVerified,
            firebaseUid: user.uid,
            token,
          },
        );
        onSave();
      }
    } catch (error) {
      const { code } = (error as FirebaseError);
      let errMsg;
      if (code === 'auth/email-already-in-use') {
        const existingCredentials = await API.checkUncredentialedEmail(email);
        if (!existingCredentials.hasEmail && existingCredentials.hasGoogle) {
          if (!firebaseUser) {
            errMsg = NewerCredentialRequired;
          } else {
            try {
              const credential = EmailAuthProvider.credential(email, pwd);
              const emailCredential = await linkWithCredential(firebaseUser, credential);
              if (emailCredential) {
                const { user } = emailCredential;
                const token = await user.getIdToken();

                await API.signUpWithEmail(
                  {
                    email,
                    isEmailVerified: user.emailVerified,
                    firebaseUid: user.uid,
                    token,
                  },
                );
                onSave();
              } else {
                errMsg = UnexpectedError;
              }
            } catch (linkErr) {
              const { code: linkCode } = (linkErr as FirebaseError);
              if (linkCode === 'auth/requires-recent-login') {
                errMsg = NewerCredentialRequired;
              } else if (linkCode === 'auth/provider-already-linked') {
                const emailCredential = await signInWithEmailAndPassword(auth, email, pwd);
                if (emailCredential) {
                  const { user } = emailCredential;
                  const token = await user.getIdToken();
                  await API.signUpWithEmail(
                    {
                      email,
                      isEmailVerified: user.emailVerified,
                      firebaseUid: user.uid,
                      token,
                    },
                  );
                  onSave();
                } else {
                  errMsg = UnexpectedError;
                }
              } else {
                throw linkErr;
              }
            }
          }
        } else {
          errMsg = (
            <>
              Email already exists.  Please
              {' '}
              <Link href="/signin" className="underline">

                Sign In

              </Link>
              .
            </>
          );
        }
      } else if (code === 'auth/invalid-email') {
        setEmailErr('Invalid email');
        return;
      } else {
        errMsg = 'Something went wrong.  Please try again later.';
      }
      setSubmitErr(errMsg);
    }
    setIsSaving(false);
  };

  return (
    <Dialog open>

      <div className="p-4">
        <h1 className="text-xl font-bold m-4">Add an email sign-in to your account</h1>
        <DialogContent>
          <form>
            <div className="mb-4 w-full">
              <FormControl fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  type="email"
                  label="Email"
                  inputMode="email"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={checkEmail}
                  value={email}
                  fullWidth
                  autoComplete="email"
                  error={!!emailErr}
                />
                {emailErr && <div className="text-red">{emailErr}</div>}
              </FormControl>
            </div>
            <div className="mb-4 w-full">
              <FormControl fullWidth>
                <InputLabel htmlFor="pwd">Password</InputLabel>
                <OutlinedInput
                  id="pwd"
                  type={showPwd ? 'text' : 'password'}
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  onBlur={() => checkPwd(setPwdErr, pwd)}
                  label="Password"
                  fullWidth
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPwd(!showPwd)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPwd ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  autoComplete="new-password"
                  error={!!pwdErr}
                />
                {pwdErr && <div className="text-red">{pwdErr}</div>}
              </FormControl>
            </div>
            <div className="mb-4 w-full">
              <FormControl fullWidth>
                <InputLabel htmlFor="rt-pwd">Retype Password</InputLabel>
                <OutlinedInput
                  id="rt-pwd"
                  type={showRtPwd ? 'text' : 'password'}
                  value={rtPwd}
                  onChange={(e) => setRtPwd(e.target.value)}
                  onBlur={() => checkPwd(setRtPwdErr, rtPwd)}
                  label="Retype Password"
                  fullWidth
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle retype password visibility"
                        onClick={() => setShowRtPwd(!showRtPwd)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showRtPwd ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  autoComplete="new-password"
                  error={!!pwdErr}
                />
                {rtPwdErr && <div className="text-red">{rtPwdErr}</div>}
              </FormControl>
            </div>
            {submitErr && <div className="text-red mb-2">{submitErr}</div>}
          </form>
        </DialogContent>
        <DialogActions className="text-right">
          <Button
            variant="contained"
            className="btn-primary-outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className="btn-primary"
            onClick={signUp}
            disabled={isSaving || !email || !pwd || !rtPwd}
          >
            Save
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
