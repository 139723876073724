import { Emoji } from 'elements/Emoji';
import { WEB_ASSETS_URL, webAssetUrl } from 'lib/utils/imageUtils';
import { BenefitsModule } from './BenefitsModule';

export const BenefitModules = () => {
  const benefits = [
    {
      id: 4,
      bgColor: 'bg-hintgray',
      heading: (
        <div className="w-full">
          The data on your content — all of it — no matter where it&apos;s published.
        </div>
      ),
      isLazy: true,
      text: (
        <div>
          Want to know how a campaign performed on multiple platforms?
          <p className="my-2" />
          Easily view and share relevant metrics of any given post or campaign,
          whether or not it came from YOU.
        </div>
      ),
    },
    {
      id: 12,
      bgColor: 'bg-gray-200',
      heading: (
        <div className="w-full">
          You deserve more than a
          <span className="font-semibold">
          &nbsp;resume&nbsp;
            <Emoji emoji="📝" description="memo emoji" />
          </span>
          {' '}
          or
          <span className="font-semibold">
          &nbsp;reel&nbsp;
            <Emoji emoji="📽️" description="film projector emoji" />
          </span>
        </div>
      ),
      isLazy: false,
      text: (
        <div className="text-xl">
          Finally, all of your work in one place.
          Track every piece of content
          you&apos;ve ever created!
          <p className="my-2" />
          Simply paste any content link and give credit to yourself and
          others involved!
        </div>
      ),
    },
    {
      id: 21, // mp4 is 21
      bgColor: 'bg-hintgray',
      heading: (
        <div className="w-full">
          Never have to ask yourself
          <span className="font-semibold">
            <br />
            who made that?&nbsp;
            <Emoji emoji="🤔" description="thinking face emoji" />
          </span>
          &nbsp;again
        </div>
      ),
      isLazy: true,
      text: (
        <div>
          Find out who created what you “like” with a quick search.
          Gondola keeps track of the amazing photographers, designers, artists, etc
          behind any post on social media.
        </div>
      ),
    },
    {
      id: 3,
      bgColor: 'bg-gray-200',
      heading: (
        <div className="w-full">
          Discover or be Discovered
        </div>
      ),
      isLazy: true,
      text: (
        <div>
          This is more than a search bar. Find talent for your next gig,
          search local creators to swap inspo, or connect with the team behind your dream brand.
          Your next big opportunity is one click away!
        </div>
      ),
    },
  ];

  return (
    <>
      {benefits.map((benefit) => (
        <div className={benefit.bgColor} key={benefit.id}>
          <BenefitsModule
            id={benefit.id}
            heading={benefit.heading}
            description={benefit.text}
            imgUrl={`${WEB_ASSETS_URL}home/benefit-${benefit.id}.mp4`}
            circleImageUrl={webAssetUrl(`home/benefit-${benefit.id}.svg`)}
            circleImageAlt="benefit icon"
            isLazy={benefit.isLazy}
          />
        </div>
      ))}
    </>
  );
};
