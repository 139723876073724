import { ReactNode } from 'react';
import {
  Analytics,
  Business,
  ContactPage,
  Description,
  PersonPinCircle,
} from '@mui/icons-material';
import { Answers, PrimaryReason, WhoAmI } from 'lib/types/users';

type Question = {
  [key: string]: {
    reason: PrimaryReason;
    icon: ReactNode;
    title: string;
    sub: string;
  }[];
};

interface Props {
  who?: WhoAmI;
  onNext: (a: Answers) => void;
  onBack: () => void;
}

export const Step2 = ({
  who, onBack, onNext,
}: Props) => {
  const reasons: Question = {
    solo: [
      {
        reason: 'portfolio',
        icon: <ContactPage fontSize="large" />,
        title: 'Build a digital creative portfolio',
        sub: 'I\'m here to build my digital creatrive portfolio.',
      },
      {
        reason: 'analytics',
        icon: <Analytics fontSize="large" />,
        title: 'Track my account analytics',
        sub: 'I\'m here to track my social analytics across platform.',
      },
      {
        reason: 'gigs',
        icon: <Business fontSize="large" />,
        title: 'Looking to find gigs',
        sub: 'I\'m looking to find gigs and new creative opportunities.',
      },
    ],
    team: [
      {
        reason: 'portfolio',
        icon: <ContactPage fontSize="large" />,
        title: 'Compile my work for my employer or others into a digital portfolio',
        sub: 'I\'m here to build my digital creative portfolio.',
      },
      {
        reason: 'analytics',
        icon: <Analytics fontSize="large" />,
        title: 'Track all the analytics for posts our team has worked on',
        sub: 'I\'m here to track my social analytics across platform.',
      },
      {
        reason: 'hiring',
        icon: <PersonPinCircle fontSize="large" />,
        title: 'Prospecting new creators for our team',
        sub: 'I\'m looking to find creators to work with.',
      },
    ],
    employer: [
      {
        reason: 'portfolio',
        icon: <Description fontSize="large" />,
        title: 'Track our content across all of our social platforms',
        sub: 'I\'m here to build our digital creative portfolio.',
      },
      {
        reason: 'analytics',
        icon: <Analytics fontSize="large" />,
        title: 'Gather data on my team\'s creative work',
        sub: 'I\'m here to credit our posts and gather creator-level analytics.',
      },
      {
        reason: 'hiring',
        icon: <PersonPinCircle fontSize="large" />,
        title: 'Looking to hire creators',
        sub: 'I\'m looking to find creators for opportunities at our company.',
      },
    ],
    influencer: [
      {
        reason: 'analytics',
        icon: <Description fontSize="large" />,
        title: 'Gather data and track all of my creative work',
        sub: 'I\'m here to credit my posts and gather my analytics in one place.',
      },
      {
        reason: 'hiring',
        icon: <PersonPinCircle fontSize="large" />,
        title: 'Looking to hire creators',
        sub: 'I\'m looking to hire creators to work with me.',
      },
    ],
  };

  const onClick = (reason: PrimaryReason) => {
    onNext({ reason });
  };

  if (!who) return null;

  return (
    <>
      <h2 className="font-semibold text-xl mb-2">What is your primary reason for using Gondola?</h2>
      {
        reasons[who].map((q) => (
          <button
            type="button"
            key={q.reason}
            className="bg-white border hover:border-alpineTeal rounded-md p-4 mb-2 w-full"
            onClick={() => onClick(q.reason)}

          >
            <div className="flex items-center">
              <div className="p-2 pr-6">
                {q.icon}
              </div>
              <div>
                <div className="md:text-lg font-semibold text-left">{q.title}</div>
                <div className="text-sm text-left">{q.sub}</div>
              </div>
            </div>
          </button>
        ))
      }
      <div className="flex justify-between">
        <button
          type="button"
          className="btn-primary-outlined bg-white"
          onClick={onBack}
        >
          Go Back
        </button>
      </div>
    </>
  );
};
