import { useRouter } from 'next/router';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { BasicPaginationInfo } from 'server/api/types';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { NoPaginationBox } from './NoPaginationBox';

interface Props {
  paging: BasicPaginationInfo;
  /** By default, anonymous users may only see the first page.
   * Set to true so they can see all pages. */
  canSeeAllPages?: boolean;
}

const extractUsernameFromPath = (path: string) => path.split('?')[0];

export const Pagination = ({
  paging, canSeeAllPages = false,
}: Props) => {
  const router = useRouter();
  const currentUser = useCurrentUser();

  const goTo = (page: number) => {
    // TODO(Jordan): There must be a less hacky way to do this.
    const newPathname = router.pathname === '/[profileuser]'
      ? extractUsernameFromPath(router.asPath)
      : router.pathname;
    const newQuery = { ...router.query, ...{ page } };
    router.push({
      query: newQuery,
      pathname: router.pathname,
    },
    {
      query: newQuery,
      pathname: newPathname,
    });
  };

  if (!paging || paging?.lastPage <= 1) {
    return null;
  }

  if (!currentUser && !canSeeAllPages) return (<div className="my-4"><NoPaginationBox /></div>);

  return (
    <div className="my-6 flex gap-2 justify-end items-center text-xl">
      {paging.page >= 2 && (
        <>
          <IconButton onClick={() => goTo(1)}>
            <KeyboardDoubleArrowLeft fontSize="large" />
          </IconButton>
          <IconButton onClick={() => goTo(paging.page - 1)}>
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
        </>
      )}
      <span>
        Page&nbsp;
        {paging.page}
      </span>
      <IconButton
        onClick={() => goTo(paging.page + 1)}
        disabled={!paging.nextPage || paging?.nextPage === paging?.page}
      >
        <KeyboardArrowRight fontSize="large" />
      </IconButton>
    </div>
  );
};
